const config = {
    local: {
        API_BASE_URL: 'http://localhost:8000/api/v1',
    },
    development: {
        API_BASE_URL: 'http://188.8.9.31:8086/api/v1',
    },
    production: {
        API_BASE_URL: 'https://unified-service-ioznuckdnh.ap-northeast-1.fcapp.run/api/v1',
    },
};

const env = process.env.REACT_APP_ENV || 'production';

export default config[env];
