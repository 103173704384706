import axios from 'axios';
import config from '../config';

const api = axios.create({
    baseURL: config.API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// 添加请求拦截器
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('authToken');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 响应拦截器
api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            localStorage.removeItem('authToken');
            window.location.href = '/login';
            return Promise.reject(error);
        }

        return Promise.reject(error);
    }
);

export const login = (credentials) => api.postForm('/login', credentials);
export const register = (userData) => api.post('/register?' + userData);
export const requestPasswordReset = (email) => api.post('/password-reset-request', { email });
export const resetPassword = (token, newPassword) => api.post('/password-reset', { token, newPassword });

export const getUserProfile = () => api.get('/user/profile');
export const updateUserProfile = (userData) => api.put('/user/profile', userData);

export const getApiKeys = () => api.get('/api-keys');
export const generateApiKey = (name, models) => api.post('/api-keys', { name, allowed_models: models });
export const deleteApiKey = (keyId) => api.delete(`/api-keys/${keyId}`);
export const updateApiKey = (keyId, name, models) => api.put(`/api-keys/${keyId}`, { name, models });
export const getAvailableModels = () => api.get('/models/list');

export const getUsageStats = () => api.get('/user/usage-stats');

export default api;
