import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Layout from './components/Layout/Layout';
import Dashboard from './components/Dashboard/Dashboard';
// import UserList from './components/Users/UserList';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';
// import AccountPage from './components/Account/AccountPage';
import PrivateRoute from './components/Auth/PrivateRoute';

function App() {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route
                        path="/forgot-password"
                        element={<ForgotPassword />}
                    />
                    <Route
                        path="/reset-password/:token"
                        element={<ResetPassword />}
                    />
                    <Route path="/" element={<Layout />}>
                        <Route
                            index
                            element={
                                <PrivateRoute>
                                    <Dashboard />
                                </PrivateRoute>
                            }
                        />
                        {/* <Route path="users" element={
              <PrivateRoute>
                <UserList />
              </PrivateRoute>
            } /> */}
                        {/* <Route path="account" element={
              <PrivateRoute>
                <AccountPage />
              </PrivateRoute>
            } /> */}
                    </Route>
                </Routes>
            </Router>
        </AuthProvider>
    );
}

export default App;
