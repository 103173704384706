import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import QueryString from "qs";
import { register } from '../../services/api';
import './AuthStyles.css';

const Register = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            await register(QueryString.stringify({ email, password }));
            navigate('/login', { state: { message: 'Registration successful. Please log in.' } });
        } catch (err) {
            setError('Registration failed. Please try again.');
        }
    };

    return (
        <div className="auth-container">
            <form className="auth-form" onSubmit={handleSubmit}>
                <h2>Register</h2>
                {error && <div className="error">{error}</div>}
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                    required
                />
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    required
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    required
                />
                <button type="submit">Register</button>
                <div className="links">
                    <Link to="/login">Already have an account? Log in</Link>
                </div>
            </form>
        </div>
    );
};

export default Register;
