import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const Header = () => {
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = () => {
        logout();
        const from = location.state?.from?.pathname || '/';
        navigate(from, { replace: true });
    };

    return (
        <header className="admin-header">
            <h1>Admin Dashboard</h1>
            <nav>
                <Link to="/">Dashboard</Link>
                <Link to="/users">Users</Link>
                {user && <Link to="/account">Account Settings</Link>}
            </nav>
            {user && (
                <div className="user-info">
                    <span>Welcome, {user.name}</span>
                    <button onClick={handleLogout}>Logout</button>
                </div>
            )}
        </header>
    );
};

export default Header;
