import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import {
    getApiKeys,
    generateApiKey,
    deleteApiKey,
    getUsageStats,
    getAvailableModels,
} from '../../services/api';

const Dashboard = () => {
    const { user } = useAuth();
    const [apiKeys, setApiKeys] = useState([]);
    const [usageStats, setUsageStats] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [newKeyName, setNewKeyName] = useState('');
    const [showNewKey, setShowNewKey] = useState(false);
    const [newGeneratedKey, setNewGeneratedKey] = useState('');
    const [availableModels, setAvailableModels] = useState([]);
    const [selectedModels, setSelectedModels] = useState([]);

    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                setLoading(true);
                const [keysResponse, modelsResponse] = await Promise.all([
                    getApiKeys(),
                    //   getUsageStats(),
                    getAvailableModels(),
                ]);
                setApiKeys(keysResponse.data);
                // setUsageStats(statsResponse.data);
                setAvailableModels(modelsResponse.data.models);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch dashboard data');
                setLoading(false);
            }
        };

        fetchDashboardData();
    }, []);

    const handleCreateApiKey = async () => {
        if (!newKeyName.trim()) {
            setError('Please enter a name for the API key');
            return;
        }
        if (selectedModels.length === 0) {
            setError('Please select at least one model');
            return;
        }
        try {
            const response = await generateApiKey(newKeyName, selectedModels);
            setApiKeys([...apiKeys, response.data]);
            setNewGeneratedKey(response.data.key);
            setShowNewKey(true);
            setNewKeyName('');
            setSelectedModels([]);
            setError('');
        } catch (err) {
            setError('Failed to create new API key');
        }
    };

    const handleDeleteApiKey = async (keyId) => {
        if (window.confirm('Are you sure you want to delete this API key?')) {
            try {
                await deleteApiKey(keyId);
                setApiKeys(apiKeys.filter((key) => key.id !== keyId));
            } catch (err) {
                setError('Failed to delete API key');
            }
        }
    };

    const handleModelSelection = (modelId) => {
        setSelectedModels((prevModels) =>
            prevModels.includes(modelId)
                ? prevModels.filter((id) => id !== modelId)
                : [...prevModels, modelId]
        );
    };

    return (
        <div className="dashboard">
            {error && <div className="error-banner">{error}</div>}
            <h2>Welcome, {user.name}!</h2>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <div className="dashboard-stats">
                        <div className="stat-card">
                            <h3>Total API Calls</h3>
                            <p>{usageStats?.totalCalls || 0}</p>
                        </div>
                        <div className="stat-card">
                            <h3>This Month's Usage</h3>
                            <p>{usageStats?.monthlyUsage || 0}</p>
                        </div>
                    </div>
                    <div className="api-keys-section">
                        <h3>Your API Keys</h3>
                        <div className="create-key">
                            <input
                                type="text"
                                value={newKeyName}
                                onChange={(e) => setNewKeyName(e.target.value)}
                                placeholder="Enter new API key name"
                            />
                            <div className="model-selection">
                                <p>Select models for this API key:</p>
                                {availableModels.map((modelId) => (
                                    <label key={modelId}>
                                        <input
                                            type="checkbox"
                                            checked={selectedModels.includes(modelId)}
                                            onChange={() => handleModelSelection(modelId)}
                                        />
                                        {modelId}
                                    </label>
                                ))}
                            </div>
                            <button onClick={handleCreateApiKey}>Create New API Key</button>
                        </div>
                        {showNewKey && (
                            <div className="new-key-display">
                                <p>Your new API key (copy it now, you won't be able to see it again):</p>
                                <code>{newGeneratedKey}</code>
                                <button onClick={() => setShowNewKey(false)}>Close</button>
                            </div>
                        )}
                        <table className="api-key-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Models</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {apiKeys.map((key) => (
                                    <tr key={key.id}>
                                        <td>{key.name}</td>
                                        <td>{key.allowed_models.join(', ')}</td>
                                        <td>{new Date(key.created_at).toLocaleDateString()}</td>
                                        <td>
                                            <button onClick={() => handleDeleteApiKey(key.id)}>Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </div>
    );
};

export default Dashboard;
