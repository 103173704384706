import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { requestPasswordReset } from '../../services/api';
import './AuthStyles.css';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');

        try {
            await requestPasswordReset(email);
            setMessage('Password reset instructions have been sent to your email.');
        } catch (err) {
            setError('Failed to send password reset email. Please try again.');
        }
    };

    return (
        <div className="auth-container">
            <form className="auth-form" onSubmit={handleSubmit}>
                <h2>Forgot Password</h2>
                {error && <div className="error">{error}</div>}
                {message && <div className="message">{message}</div>}
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    required
                />
                <button type="submit">Reset Password</button>
                <div className="links">
                    <Link to="/login">Back to Login</Link>
                </div>
            </form>
        </div>
    );
};

export default ForgotPassword;
