import React, { useEffect} from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const PrivateRoute = ({ children }) => {
    const { user, loading, login: authLogin } = useAuth();
    const location = useLocation();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const token = urlParams.get('token');

        if (token) {
            authLogin({ token_type: 'bearer', token: token })
        }
    }, [location]);

    if (loading) {
        // You can return a loading spinner or component here
        return <div>Loading...</div>;
    }

    const isAuthenticated = localStorage.getItem('authToken') !== null;
    if (isAuthenticated) {
        return children;
    }

    if (!user) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};

export default PrivateRoute;
