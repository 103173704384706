import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

const Layout = () => {
    return (
        <div className="admin-layout">
            <Header />
            <div className="main-content">
                {/* <Sidebar /> */}
                <main>
                    <Outlet />
                </main>
            </div>
            {/* <Footer /> */}
        </div>
    );
};

export default Layout;
